html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  max-height: 100vh;
  width: 100%;
}

.mapboxgl-canvas {
  left: 0;
}

/**
* ----------------------------------------------
* Accordion styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  padding: 1rem 0;
  overflow: hidden;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-left: 12px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

.accordion__panel {
  padding: 0.25rem 1rem;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
